import './App.css';
import Dashboard from './components/Dashboard/Dashboard';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login/Login';

function App() {
  return (
   <Router basename='/'>
   <Routes>
     <Route path="/" element={<Login />} />
     <Route
       path="/*"
       element={
         <>
           <Dashboard/>
         </>
           
       }
     />
   </Routes>
 </Router>

  );
}

export default App;
