import React,{useState} from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import MailIcon from '@mui/icons-material/Mail';
import { NavLink } from 'react-router-dom';








const MainListItems = ()=>{

  return(
    <>
    <React.Fragment >
    <NavLink to="/Records" style={{ textDecoration: 'none', color: 'inherit' }}>
    <ListItemButton>
      <ListItemIcon>
        <DashboardIcon sx={{color:'#2ec4b6'}}/>
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    </NavLink>
    <NavLink to="/ImportExcel" style={{ textDecoration: 'none', color: 'inherit' }}>
    <ListItemButton>
      <ListItemIcon>
        <SimCardDownloadIcon sx={{color:'#2ec4b6'}}/>
      </ListItemIcon>
      <ListItemText primary="Import Excel" />
    </ListItemButton>
    </NavLink>
    {/* <NavLink to="/ImportTemplate" style={{ textDecoration: 'none', color: 'inherit' }}>
    <ListItemButton>
      <ListItemIcon>
        <ImportExportIcon sx={{color:'#2ec4b6'}}/>
      </ListItemIcon>
      <ListItemText primary="Import Template" />
    </ListItemButton>
    </NavLink> */}
    <NavLink to="/SendMail" style={{ textDecoration: 'none', color: 'inherit' }}>
    <ListItemButton>
      <ListItemIcon>
        <MailIcon sx={{color:'#2ec4b6'}}/>
      </ListItemIcon>
      <ListItemText primary="Send Mail" />
    </ListItemButton>
    </NavLink>
    
  </React.Fragment>
    </>
  )
}


  export default MainListItems;
  


