import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import LoadingSpinner from "../components/ui/LoadingSpinner";

const Records = lazy(() => import("../Pages/Records/Records"));

const SendMail = lazy(()=> import("../Pages/Send Mail/SendMail"));
const ImportExcel = lazy(()=> import("../Pages/Import Excel/ImportExcel"));
const ImportTemplate = lazy(()=> import("../Pages/Import Template/ImportTemplate"));

// Dashboard Reports Routes Below



const CustomRoutes = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        <Route exact path="/Records" element={<Records />} />
        <Route exact path="/SendMail" element={<SendMail />} />
        <Route exact path="/ImportExcel" element={<ImportExcel />} />
        <Route exact path="/ImportTemplate" element={<ImportTemplate />} />
      </Routes>
    </Suspense>
  );
};

export default CustomRoutes;
