import React,{useState} from 'react';
import { TextField, FormControl, Button, Paper, Checkbox, FormControlLabel, Grid, Alert } from '@mui/material';
import classes from './Login.module.css';
import logo from '../Login/F.png';
import {  useNavigate } from 'react-router-dom'; 


 const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(false);


const navigate = useNavigate();

const buttonStyle = { backgroundColor: '#2ec4b6' };

const handleLogin = ()=>{
if(email == 'admin' && password == '123'){
  navigate('/Records')
}else{
  setError(true)
  navigate('/')
}
}


  return <>
  
  <div className={classes.blWelcome}>
    <Paper sx={{ padding: '20px', marginBottom: '20px',width:'430px' ,borderRadius:'0.9%'}}>
   {
    error &&  <Alert severity="error">Invalid Credentials !!</Alert>
   }
   
      <h1 className={classes.pageHead}>Login</h1><br/>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth >
              <TextField variant='outlined' label='Username'  onChange={(e)=> setEmail(e.target.value)}  name='username' id='email' />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField variant='outlined' label='Password' type='password'  onChange={(e)=> setPassword(e.target.value)} name='password' id='password' />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel control={<Checkbox name='rememberMe' />} label='Remember me' />
          </Grid>
          <Grid item xs={6}>
            <Button  className='mt5'>Forgot password?</Button>
          </Grid>
          <Grid item xs={12}>
            <Button variant='contained' size='medium' type='submit' style={buttonStyle} onClick={handleLogin} className='ml6'>Login</Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
    </div>
  </>;
};


export default Login;